import React from 'react';

import {useNavigate} from 'react-router-dom';


function Home() {

    const navigate = useNavigate();


    const handleRouteBsc = () => {
        navigate("/stakebsc");
    }

    const handleRouteKardiachain = () => {
        navigate("/stakekai");
    }

    return (
        <div className="clubhouse">
            <div className={"coins_initial"}/>
            <div className="icoWarapper">
                <div className="ch-bot">
                    <div className="m-b-100">
                        <div className="ch-overlay">
                            <div>
                                <h3 className="fs-64 m-b-25 fw-900 title-home">
                                    STAKE AND GET REWARDS
                                </h3>
                                <p className="description-home">
                                    Select the blockchain in which you want to stake:
                                </p>
                            </div>
                            <div className="ch-info">
                                <button className={"clbtn fw-700 btn big bl m-l-auto m-r-auto m-t-30 "}
                                        onClick={handleRouteKardiachain}>
                                    Kardiachain
                                </button>

                                <button disabled={true}
                                        className={"clbtn fw-700 btn big bl m-l-auto m-r-auto m-t-30  disabled "}
                                        onClick={handleRouteBsc}>
                                </button>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

// Wrap and export
export default Home;