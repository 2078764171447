import "./App.css";
import "./Utils.css";
import Staking from "./Staking";
import StakingBSC from "./Staking-BSC";
import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom';
import Home from "./Home";

function App() {
    return (


        <Router>
            <Routes>
                <Route exact path='/' element={< Home/>}></Route>
                <Route exact path='/stakekai' element={<Staking/>}></Route>
                <Route exact path='/stakebsc' element={<StakingBSC/>}></Route>
            </Routes>
        </Router>
    )
}

export default App;
